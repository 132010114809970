<template>
  <div>
    <div>
      <h1 class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('setPassword')"></h1>
      <v-form @submit.prevent class="modal-form">
        <v-col cols="12">
          <v-text-field
            autocomplete="new-password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            @input="checkPassword()"
            v-model="password"
            :label="$t('yourPassword')"
            class="outlined__input login__input"
            id="setPassword"
            outlined
            required
          ></v-text-field>

          <div v-if="passwordLengthError" class="v-messages error--text">{{ $t('shortPasswordError') }}</div>
          <div v-if="passwordNumberError" class="v-messages error--text">{{ $t('passwordNumberError') }}</div>
          <div v-if="passwordUppsercaseError" class="v-messages error--text">{{ $t('passwordUppsercaseError') }}</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="new-password"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            @click:append="show3 = !show3"
            @input="checkConfirmationPassword()"
            v-model="passwordConfirmation"
            :label="$t('repeatPassword')"
            class="outlined__input login__input"
            id="setPasswordConfirmation"
            outlined
            required
          ></v-text-field>

          <div v-if="checkSameConfirmationPassword && passwordConfirmation" class="v-messages error--text">{{ $t('samePasswordsError') }}</div>
        </v-col>
      </v-form>
      <div class="ml-4 mt-4 ma-lg-16 mt-lg-8">{{ $t('passwordSecurityNotice') }}</div>
      <div class="ml-4 mt-2 ma-lg-16 mt-lg-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M4.88636 10C4.6379 10 4.47226 9.91718 4.30662 9.75154L0.248459 5.44492C-0.0828196 5.11364 -0.0828196 4.61672 0.248459 4.28545C0.579737 3.95417 1.07666 3.95417 1.40793 4.28545L4.80354 7.92951L11.7604 0.310104C12.0088 -0.0211741 12.5886 -0.103994 12.9199 0.144465C13.2511 0.392924 13.334 0.972661 13.0855 1.30394L13.0027 1.38676L5.4661 9.66872C5.38328 9.91718 5.13482 10 4.88636 10Z" fill="#3D3D3F"/>
        </svg>
        {{ $t('tenCharacters') }}
      </div>
      <div class="ml-4 mt-2ma-lg-16 mt-lg-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M4.88636 10C4.6379 10 4.47226 9.91718 4.30662 9.75154L0.248459 5.44492C-0.0828196 5.11364 -0.0828196 4.61672 0.248459 4.28545C0.579737 3.95417 1.07666 3.95417 1.40793 4.28545L4.80354 7.92951L11.7604 0.310104C12.0088 -0.0211741 12.5886 -0.103994 12.9199 0.144465C13.2511 0.392924 13.334 0.972661 13.0855 1.30394L13.0027 1.38676L5.4661 9.66872C5.38328 9.91718 5.13482 10 4.88636 10Z" fill="#3D3D3F"/>
        </svg>
        {{ $t('oneDigt') }}
      </div>
      <div class="ml-4 mt-2ma-lg-16 mt-lg-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M4.88636 10C4.6379 10 4.47226 9.91718 4.30662 9.75154L0.248459 5.44492C-0.0828196 5.11364 -0.0828196 4.61672 0.248459 4.28545C0.579737 3.95417 1.07666 3.95417 1.40793 4.28545L4.80354 7.92951L11.7604 0.310104C12.0088 -0.0211741 12.5886 -0.103994 12.9199 0.144465C13.2511 0.392924 13.334 0.972661 13.0855 1.30394L13.0027 1.38676L5.4661 9.66872C5.38328 9.91718 5.13482 10 4.88636 10Z" fill="#3D3D3F"/>
        </svg>
        {{ $t('differentCase') }}
      </div>
      <div class="ml-4 mt-2ma-lg-16 mt-lg-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M4.88636 10C4.6379 10 4.47226 9.91718 4.30662 9.75154L0.248459 5.44492C-0.0828196 5.11364 -0.0828196 4.61672 0.248459 4.28545C0.579737 3.95417 1.07666 3.95417 1.40793 4.28545L4.80354 7.92951L11.7604 0.310104C12.0088 -0.0211741 12.5886 -0.103994 12.9199 0.144465C13.2511 0.392924 13.334 0.972661 13.0855 1.30394L13.0027 1.38676L5.4661 9.66872C5.38328 9.91718 5.13482 10 4.88636 10Z" fill="#3D3D3F"/>
        </svg>
        {{ $t('useSpecialCharacetrs') }}
      </div>
      <v-col cols="12" lg="12" class="text-center mt-4">
        <button
          :disabled="loading"
          @click="submit()"
          class="main-button main-button-fixed"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="loading"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
        </button>
      </v-col>
    </div>
  </div>
</template>

<script>
import { AppStorage } from 'cuf-expo-bridge'

export default {
  name: 'setPassword',
  props: {
    phoneNumber: {
      type: String,
      default: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    birthday: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      password: null,
      passwordConfirmation: null,
      show2: false,
      show3: false,
      passwordNumberError: false,
      passwordUppsercaseError: false,
      passwordLengthError: false,
      samePasswordsError: false,
      loading: false
    }
  },

  computed: {
    checkSameConfirmationPassword () {
      return this.password !== this.passwordConfirmation
    },
    userLocale () {
      return this.$i18n.locale
    }
  },

  methods: {
    submit () {
      const source = localStorage.getItem('utmSource')
      this.loading = true
      this.$http.post('/auth/sign_up/', {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        email: this.email,
        birthday: this.birthday,
        language: this.userLocale,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        source: source !== undefined ? source : null
      })
        .then(response => {
          this.loading = false
          this.loginSuccessful(response)
        })
        .catch((_error) => {
          this.loading = false
        })
    },
    loginSuccessful (req) {
      if (!req.data.token) {
        this.loginFailed()
        return
      }
      this.error = false
      // const obj = JwtDecode(req.data.token)

      AppStorage.set('token', req.data.token)
        .then(() => this.$store.dispatch('login', req.data.token))
        .then(response => {
          // this.$store.dispatch('changeLocale', obj.locale)
          this.$store.dispatch('getMessages')
          this.$store.dispatch('getInfo')
          // this.$i18n.locale = obj.locale
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.$router.replace(this.$route.query.redirect || '/dashboard')
          } else {
            this.$router.replace(this.$route.query.redirect || '/documents')
          }
        })
    },
    checkConfirmationPassword () {
      this.samePasswordsError = this.password !== this.passwordConfirmation
    },
    checkPassword () {
      if (this.password.length > 9) {
        this.passwordLengthError = false
      } else {
        this.passwordLengthError = true
      }

      this.passwordNumberError = !/\d/.test(this.password)
      this.passwordUppsercaseError = !/[A-Z]/.test(this.password)
      this.samePasswordsError = this.password !== this.passwordConfirmation
    }
  }
}
</script>

<style lang="scss">
</style>
