<template>
  <div>
    <v-card class="login__container mx-auto">
      <v-card-text>
        <div class="container text-center mt-2">
          <img class="logo" :src="logoSvg" :alt="logoAlt" />
        </div>
        <div v-if="!clientExists && !codeVerification && !setPassword">
          <h1 class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('create_account')"></h1>
          <form class="ma-6 ma-lg-16 mt-lg-8" @submit.prevent="submit">
            <v-row>
              <v-col class="pt-4 pb-0" cols="12" sm="12">
                <v-text-field
                  :label="$t('firstName')"
                  class="login__input"
                  hide-details="true"
                  outlined
                  required
                  autofocus
                  v-model="firstName"
                ></v-text-field>
                <div class='error-notice' v-if="firstName && validateCyrillic(firstName)">
                  {{ $t('dont_use_cyrillic') }}
                </div>
                <div class='error-notice' v-if="firstName && (validateNumbers(firstName) || validateSpecialCharacters(firstName) || validateSpaces(firstName))">
                  {{ $t('dont_use_numbers') }}
                </div>
              </v-col>
              <v-col class="pt-4 pb-0" cols="12" sm="12">
                <v-text-field
                  :label="$t('lastName')"
                  class="login__input"
                  hide-details="true"
                  outlined
                  required
                  v-model="lastName"
                ></v-text-field>
                <div class='error-notice' v-if="lastName && validateCyrillic(lastName)">
                  {{ $t('dont_use_cyrillic') }}
                </div>
                <div class='error-notice' v-if="lastName && (validateNumbers(lastName) || validateSpecialCharacters(lastName) || validateSpaces(lastName))">
                  {{ $t('dont_use_numbers') }}
                </div>
              </v-col>
              <v-col class="pt-4 pb-0" cols="12" sm="12">
                <vue-phone-number-input
                :label="$t('phone')"
                class="login__input"
                no-validator-state="true"
                no-example="true"
                :translations="translations"
                :only-countries="['PL', 'RO', 'BG', 'GB', 'AT', 'BE', 'DK', 'DE', 'IE', 'NL', 'UA']"
                @update="updatePhoneNumber"
                outlined
                required
                v-model="temporaryPhoneNumber" />
                <div class='error-notice' v-if="temporaryPhoneNumber && !validPhone">
                  {{ $t('wrongPhoneRegistration') }}
                </div>
              </v-col>
              <v-col class="pt-6 pb-0" cols="12" sm="12">
                <v-text-field
                  :label="$t('email')"
                  class="login__input"
                  outlined
                  required
                  hide-details="true"
                  v-model="email"
                ></v-text-field>
                <div class='error-notice' v-if="email && !validEmail">
                  {{ $t('wrongEmailRegistration') }}
                </div>
                <div class='error-notice' v-if="email && (validateCyrillic(email) || validateSpaces(email))">
                  {{ $t('dont_use_cyrillic') }}
                </div>
              </v-col>
              <v-col class="pt-4 pb-0" cols="12" sm="12">
                <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  class="birthday-menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatBirthday"
                      :label="$t('birthday')"
                      prepend-icon="mdi-calendar"
                      hint="DD/MM/YYYY"
                      persistent-hint
                      class="login__input"
                      hide-details="true"
                      outlined
                      required
                      readonly
                      @blur="birthday = parseDate(formatBirthday)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="birthday"
                    no-title
                    :locale="userLocale"
                    @input="handleDateSelection"
                  ></v-date-picker>
                </v-menu>
                <div class='error-notice' v-if="formatBirthday && !validBirthday()">
                  {{ $t('wrong_birthday') }}
                </div>
              </v-col>
              <v-col cols="12" lg="12" class="text-center">
                <button
                  :disabled="!validAllData || loading"
                  type="submit"
                  class="main-button main-button-fixed"
                >{{ $t('next') | uppercase }}
                <v-progress-circular
                  v-if="loading"
                  :size="15"
                  color="grey"
                  indeterminate
                ></v-progress-circular>
                </button>
              </v-col>
            </v-row>
          </form>
          <div class="text-center">
            <div>{{ $t('have_account') }}</div>
            <router-link :to="'login'">
              <button class="main-button-white-small main-button-fixed d-xs-inline mt-0">
                <span>{{ $t('login_button') }}</span>
                <v-icon
                    small
                    class="ml-2 arrow-icon arrow-gradient"
                  >
                    fas fa-arrow-right
                  </v-icon>
              </button>
            </router-link>
          </div>
        </div>
        <clientExists v-if="clientExists" />
        <codeVerification v-if="codeVerification && !setPassword"
          @correctTokenValidation="correctTokenValidation"
          :phoneNumber="phoneNumber"
          :firstName="firstName"
          :lastName="lastName"
          :email="email"
          :birthday="birthday"
        />
        <setPassword v-if="setPassword"
          :phoneNumber="phoneNumber"
          :firstName="firstName"
          :lastName="lastName"
          :email="email"
          :birthday="birthday"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import clientExists from '@/components/registration/clientExists.vue'
import codeVerification from '@/components/registration/codeVerification.vue'
import setPassword from '@/components/registration/setPassword.vue'
import { analyticsModule, getIsExpoApp } from 'cuf-expo-bridge'

export default {
  name: 'signUp',
  data () {
    return {
      birthdayMenu: false,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      temporaryPhoneNumber: null,
      phoneIsValid: false,
      email: null,
      birthday: null,
      formatBirthday: null,
      clientExists: false,
      codeVerification: false,
      setPassword: false,
      loading: false
    }
  },
  components: {
    clientExists,
    codeVerification,
    setPassword
  },
  computed: {
    ...mapGetters({ currentClient: 'currentClient' }),

    validAllData () {
      return (this.validFirstName) &&
        (this.validLastName) &&
        (this.temporaryPhoneNumber && this.validPhone) &&
        (this.validEmail && !this.validateSpaces(this.email)) &&
        this.validBirthday()
    },

    translations () {
      return {
        countrySelectorLabel: this.$t('countrySelectorLabel'),
        countrySelectorError: this.$t('countrySelectorError'),
        phoneNumberLabel: this.$t('phoneNumberLabel'),
        example: this.$t('example')
      }
    },

    validFirstName () {
      return this.firstName != null && !this.validateCyrillic(this.firstName) && !this.validateNumbers(this.firstName) && !this.validateSpecialCharacters(this.firstName) && !this.validateSpaces(this.firstName)
    },

    validLastName () {
      return this.lastName != null && !this.validateCyrillic(this.lastName) && !this.validateNumbers(this.lastName) && !this.validateSpecialCharacters(this.lastName) && !this.validateSpaces(this.lastName)
    },

    validPhone () {
      if (this.temporaryPhoneNumber) {
        const phoneRegex = /^\d{9}$/
        return !!this.temporaryPhoneNumber.replace(/\s+/g, '').match(phoneRegex)
      } else {
        return false
      }
    },

    validEmail () {
      if (this.email) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return !!this.email.match(emailRegex)
      } else {
        return false
      }
    },

    userLocale () {
      return this.$i18n.locale
    },

    afterLogout () {
      return this.$route.query.type === 'logout'
    },
    afterAutologout () {
      return this.$route.query.type === 'autologout'
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },
    logoAlt () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Panel Klienta Euro-tax" '
      } else {
        return 'Panel Klienta CUF'
      }
    }
  },
  created () {
    if (!getIsExpoApp() && (this.$i18n.locale === 'bg' || location.host.includes('.bg'))) {
      this.$router.push('/login')
    }
  },
  updated () {
  },
  watch: {
    birthday (val) {
      this.formatBirthday = this.formatDate(this.birthday)
    }
  },
  methods: {
    async submit () {
      const token = await this.$recaptcha('sign_up')
      this.send = true
      this.$http.post('/auth/unique_login/', {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        email: this.email,
        birthday: this.birthday,
        language: this.userLocale,
        token: token
      })
        .then(response => {
          analyticsModule.logEvent({ name: 'sign_up', params: { method: 'email', email: this.email } })

          if (response.data.client_exists) {
            this.clientExists = true
          } else {
            this.codeVerification = true
          }
        })
    },

    correctTokenValidation () {
      this.codeVerification = false
      this.setPassword = true
    },

    updatePhoneNumber (value) {
      this.phoneNumber = value.formattedNumber
      this.phoneIsValid = value.isValid
    },

    validateCyrillic (value) {
      const cyrillicPattern = /[\u0400-\u04FF]/
      return cyrillicPattern.test(value)
    },

    validateNumbers (value) {
      const numberPattern = /\d/
      return numberPattern.test(value)
    },

    validateSpecialCharacters (value) {
      const specialCharactersPattern = /[!@#$%^&*()_+={}|\\:;<>,.?/~]/
      return specialCharactersPattern.test(value)
    },

    validateSpaces (value) {
      const spacesPattern = /\s/
      return spacesPattern.test(value)
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    handleDateSelection (date) {
      this.birthday = date
      this.formatBirthday = this.formatDate(date)
      this.birthdayMenu = false
    },

    validBirthday () {
      if (this.formatBirthday) {
        const comparisonDate = new Date('1920', '01', '01')

        const today = new Date()
        const minAgeDate = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate())

        const dateParts = this.formatBirthday.split('/')
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10)
        const inputDate = new Date(year, month, day)

        return inputDate > comparisonDate && inputDate <= minAgeDate
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
  .v-date-picker-table {
    tbody tr {
      height: 30px !important;
    }
  }
  .login__container {
    width: 574px;
    min-height: 586px;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .login__info-text {
    line-height: 1.55;
    text-align: left;
  }

  .login__input {
    fieldset {
      color: transparent !important;
    }

    .v-input__slot {
      background-color: rgba(180, 180, 180, 0.1) !important;
      border-radius: 10px;
      border: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px rgb(247 247 247) inset !important;
  }

  .arrow-gradient {
    background: linear-gradient(90deg, #F57921 0.05%, #D2116E 76.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .input-tel__input {
    background-color: rgba(180, 180, 180, 0.1) !important;
    border-radius: 10px !important;
    border: none !important;
    padding: 0 12px !important;
    min-height: 56px !important;
  }

  .input-country-selector {
    min-height: 56px !important;
  }

  .country-selector__country-flag {
    margin-top: 7px !important;
  }

  .country-selector__input {
    min-height: 56px !important;
  }

  .vue-phone-number-input .select-country-container {
    flex: 0 0 110px !important;
    width: 110px !important;
    min-width: 110px !important;
  }
</style>
