<template>
  <div>
    <div v-if="!displayMoreInfo">
      <h1 class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('are_you_client')"></h1>
      <div class="container text-center mt-2">
        <img src="@/assets/document_with_calculator.svg" alt="" />
      </div>
      <div>{{ $t('exists_client_information') }}</div>
      <v-col cols="12" lg="12" class="text-center">
        <router-link :to="'login'">
          <button
            class="main-button main-button-fixed"
          >{{ $t('move_to_login') | uppercase }}</button>
        </router-link>
      </v-col>
      <v-col cols="12" class="text-center grey-link mt-0 pt-0">
        <a class="custom-grey-link" @click="displayMoreInfo = true">
          <span class="svg-icon">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.99993 1.21431C4.87157 1.21431 0.714216 5.37166 0.714216 10.5C0.714216 15.6284 4.87157 19.7857 9.99993 19.7857C15.1283 19.7857 19.2856 15.6284 19.2856 10.5C19.2856 5.37166 15.1283 1.21431 9.99993 1.21431Z" stroke="#B4B4B4" stroke-width="2"/>
              <path d="M10.0007 11.5478V15.3407" stroke="#B4B4B4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.99991 5.37069C9.192 5.37069 8.53705 6.02563 8.53705 6.83354C8.53705 7.64146 9.192 8.2964 9.99991 8.2964C10.8078 8.2964 11.4628 7.64146 11.4628 6.83354C11.4628 6.02563 10.8078 5.37069 9.99991 5.37069Z" fill="#B4B4B4"/>
              <path d="M9.99988 5.72789C9.38921 5.72789 8.89416 6.22293 8.89416 6.8336C8.89416 7.44427 9.38921 7.93932 9.99988 7.93932C10.6105 7.93932 11.1056 7.44427 11.1056 6.8336C11.1056 6.22293 10.6105 5.72789 9.99988 5.72789Z" stroke="#B4B4B4"/>
            </svg>
          </span>
          {{ $t('where_find_login') }}
        </a>
      </v-col>
    </div>
    <div v-if="displayMoreInfo">
      <button class="grey-link ml-0" @click="displayMoreInfo = false">
        <v-icon
          small
          class="mr-2 arrow-icon"
        >
          fas fa-arrow-left
        </v-icon>
        <span>{{ $t('return') }}</span>
      </button>
      <h4 class="login__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('where_find_login')"></h4>
      <div>{{ $t('where_find_login_description1') }}</div>
      <div class="container text-center mt-2">
        <img src="@/assets/find_documents.svg" alt="" />
      </div>
      <div>{{ $t('where_find_login_description2') }}</div>
      <div class="mt-6" v-html="$t('where_find_login_description3')"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'clientExists',
  data () {
    return {
      displayMoreInfo: false
    }
  }
}
</script>

<style lang="scss">
  .custom-grey-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #333; /* Kolor tekstu */
  }

  .svg-icon {
    margin-right: 5px; /* Odpowiada za odstęp między ikoną SVG a tekstem */
    display: inline-block;
  }
</style>
