<template>
  <div>
    <div>
      <h4 class="login__info-text mt-6 mb-6 ma-lg-16 mt-lg-8" v-html="$t('verify_your_phone')"></h4>
      <div>{{ $t('verify_your_phone_info') }}</div>
      <div class="text-orange">{{ hiddenPhoneNumber }}</div>
      <div class="mt-8">{{ $t('enter_code') }}</div>
      <v-otp-input
        class="mt-4"
        v-model="verificationCode"
        length="6"
        type="number"
      ></v-otp-input>
      <div class='error-notice' v-if="wrong_token">
        {{ $t('wrong_token') }}
      </div>
      <v-col cols="12" lg="12" class="text-center mt-4">
        <button
          :disabled="!verificationCode || (verificationCode && verificationCode.length < 6) || sendingVerificationCode"
          @click="submitVerificationCode"
          class="main-button main-button-fixed"
        >{{ $t('next') | uppercase }}
        <v-progress-circular
          v-if="sendingVerificationCode"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </button>
      </v-col>
      <div class="mt-8 text-center">
        {{ $t('didnt_recieve_code') }}
        <button
          :disabled="sendingAgainVerificationToken"
          @click="sendAgainVerificationToken"
          class="main-button-white-small main-button-fixed d-xs-inline mt-0"
        >
          <span>{{ $t('send_again') }}</span>
        </button>
        <v-progress-circular
          v-if="sendingAgainVerificationToken"
          :size="15"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'codeVerification',
  props: {
    phoneNumber: {
      type: String,
      default: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    birthday: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      verificationCode: '',
      wrong_token: false,
      sendingVerificationCode: false,
      sendingAgainVerificationToken: false
    }
  },
  computed: {
    hiddenPhoneNumber () {
      if (this.phoneNumber) {
        const prefix = this.phoneNumber.substring(0, 3)
        const middle = 'XXX XXX'
        const suffix = this.phoneNumber.substring(9)
        return `${prefix} ${middle} ${suffix}`
      } else {
        return ''
      }
    },
    userLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    submitVerificationCode () {
      this.sendingVerificationCode = true
      this.$http.post('/auth/verify_token/', {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        email: this.email,
        birthday: this.birthday,
        language: this.userLocale,
        token: this.verificationCode
      })
        .then(response => {
          this.sendingVerificationCode = false
          this.$emit('correctTokenValidation')
        })
        .catch((_error) => {
          this.wrong_token = true
          this.sendingVerificationCode = false
        })
    },

    sendAgainVerificationToken () {
      this.sendingAgainVerificationToken = true
      this.$http.post('/auth/unique_login/', {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        email: this.email,
        birthday: this.birthday,
        language: this.userLocale
      })
        .then(response => {
          this.sendingAgainVerificationToken = false
        })
        .catch((_error) => {
          this.sendingAgainVerificationToken = false
          this.$router.push('/login?type=somethingWentWrong')
        })
    }
  }
}
</script>

<style lang="scss">
  .text-orange {
    color: #F26822;
  }

  .v-otp-input {
    .v-text-field__slot {
      border-radius: 10px;
      background: rgba(180, 180, 180, 0.10);
    }
  }
</style>
